<template>
  <ul class="list-group">
    <li
      class="d-flex align-items-center justify-content-between"
      v-for="automation in automations"
      :key="automation.id"
    >
      <div
        class="trigger d-flex align-items-center mt-3 text-nowrap font-weight-bold"
        v-b-tooltip.hover.bottom="$t('actions.view')"
        @click="showViewModal(automation)"
      >
        <hs-icon variant="light" :icon="getAutomationIcon(automation.integration)" class="handle mr-3" />
        {{
          $t(
            `sparkmembers.contents.views.editor.components.${type}.components.automations.list.types.${
              automation.integration
            }`
          )
        }}
      </div>

      <button class="btn btn-link ml-2 p-0" @click="$emit('remove', automation.id)">
        <hs-icon
          variant="light"
          icon="trash-alt"
          class="font-size-lg text-danger"
          v-b-tooltip.hover.bottom="$t('actions.delete')"
        />
      </button>
    </li>

    <DetailsEditorModal :automation="selectedAutomation" v-on="$listeners" />
  </ul>
</template>

<script>
import DetailsEditorModal from './DetailsEditorModal';
import lodashCloneDeep from 'lodash.clonedeep';

export default {
  name: 'List',
  props: {
    type: { type: String },
    automations: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedAutomation: {},
    };
  },
  components: {
    DetailsEditorModal,
  },
  methods: {
    getAutomationIcon(type) {
      const types = ['Sendgrid', 'Messages', 'Student::Categorization', 'Webhook', 'HeroWebhook'];
      const variants = ['envelope', 'comments', 'tag', 'brackets', 'brackets'];
      return variants[types.indexOf(type)];
    },
    showViewModal(automation) {
      this.selectedAutomation = lodashCloneDeep(automation);
      this.$bvModal.show('details-editor-modal');
    },
  },
};
</script>

<style lang="scss" scoped>
.trigger {
  cursor: pointer;
}
</style>
